<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="/">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>查询记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
      >
        <el-form-item label="搜索">
          <el-input v-model="key" placeholder="请输入关键词"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="selList()">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>查询记录管理</span>
        </div>
         <el-tabs v-model="query.state" @tab-click="tabClick">
            <el-tab-pane
            v-for="item in statelist"
            :label="item.name"
            :name="item.id.toString()"
            :key="item.id"
          >
          </el-tab-pane>
        </el-tabs>

        <!-- 列表 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
          @sort-change="sortChange"
          @filter-change="filterTag"
        >
          <el-table-column prop="createTime" label="提交时间"></el-table-column>
          <el-table-column prop="weiXin" label="微信"></el-table-column>
          <el-table-column prop="qq" label="QQ"></el-table-column>
          <el-table-column prop="mobile" label="电话"></el-table-column>
          <el-table-column prop="area" label="查询地址"></el-table-column>
          <el-table-column prop="tuitionFee" label="查询学费"></el-table-column>
          <el-table-column prop="label" label="查询要求"></el-table-column>
          <el-table-column prop="allCount" label="查询个数"></el-table-column>
          <el-table-column prop="sourceName" label="来源类型"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.state == 1">未处理</el-tag>
              <el-tag type="success" v-else-if="scope.row.state == 2"
                >已处理</el-tag
              >
              <el-tag type="danger" v-else>已取消</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="handleName" label="处理人"></el-table-column>
          <el-table-column prop="handleTime" label="处理时间"></el-table-column>

          <!-- 操作 -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip
                  content="处理"
                  placement="top"
                  v-if="scope.row.state == 1"
                >
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-circle-check"
                    @click="handClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  content="取消"
                  placement="top"
                  v-if="scope.row.state == 1"
                >
                  <el-button
                    type="warning"
                    size="mini"
                    icon="el-icon-remove"
                    @click="canqelClick(scope.row)"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 50, 100]"
            layout="total,sizes,prev,pager,next,jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
export default {
  data() {
    return {
      domain: url.getDomain(),
      query: {
        state: "1",
      }, //对象
      key: "",
      form: {},
      dialogVisible: false,
      submiting: false,
      loading: false,
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage4: 1,
      statelist: [],
    };
  },
  methods: {
    handleSizeChange: function (val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange: function (val) {
      this.currentPage4 = val;
      this.getList();
    },
    // 排序
    sortChange: function (sortColumn) {
      this.query.sort = sortColumn.sort;
      this.query.order = sortColumn.order;
      this.getList();
    },
    // 筛选
    filterTag: function (filter) {
      var val = filter["isEnable"][0];
      this.getList();
    },
    // 切换
    tabClick: function () {
      (this.tableData = []), this.getList();
    },

    // 列表
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageSize = _this.pageSize;
      params.pageIndex = _this.currentPage4;
      params.key = _this.key;
      var link = url.getSchool("GetList_SelRecord");
      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    // 查询
    selList: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    //获取类状态列表
    state: function () {
      var _this = this;
      var link = url.getSchool("GetAllState");
      $.get(link, {}, (data) => {
        _this.statelist = data;
      });
    },

    handClick: function (row) {
      var _this = this;

      var link = url.getSchool("Handle_SelRecord"); //获取处理状态
      var id = row.id;

      this.$confirm("确定要处理吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    canqelClick: function (row) {
      var _this = this;

      var link = url.getSchool("Cancel_SelRecord"); //获取处理状态
      var id = row.id;

      this.$confirm("确定要取消吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //编辑
    editClick: function (row) {
      this.form = Object.assign({}, row);
      this.dialogVisible = true;
    },
  },
  mounted() {
    //获取类型列表
    this.state();

    //   列表
    this.getList();
  },
};
</script>
